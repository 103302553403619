import React from "react";
import { navigate } from "gatsby";
import {
  Avatar,
  Card,
  CardActionArea,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { Article, Compare } from "@mui/icons-material";
import { Box } from "@mui/system";

export const PostBottomSection = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {[
        {
          title: "他のブログを見る",
          url: "/blog",
          icon: <Article />,
          backgroundColor: theme.palette.primary.main,
        },
        {
          title: "スクールを比較する",
          url: "/",
          icon: <Compare />,
          backgroundColor: theme.palette.primary.main,
        },
      ].map(({ title, url, icon, backgroundColor }, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Card variant="outlined">
            <CardActionArea
              sx={{
                p: 1,
                overflow: "hidden",
              }}
              onClick={() => {
                navigate(url);
              }}
            >
              <Box display="flex">
                <Box>
                  <Avatar variant="rounded" sx={{ backgroundColor }}>
                    {icon}
                  </Avatar>
                </Box>
                <Box ml={2} component="nav">
                  <Link
                    color="inherit"
                    underline="none"
                    href={url}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {title}
                  </Link>
                </Box>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
