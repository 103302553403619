import React from "react";
import { Container, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { PageProps } from "gatsby";
import { Layout } from "../../containers/Layout";
import { BlockContent } from "./BlockContent";
import { PostTopSection } from "./PostTopSection";
import { PostBottomSection } from "./PostBottomSection";
import { Seo } from "../../components/Seo";
import { useBudoux } from "../../lib/helpers";
import { format, formatDistanceToNow } from "date-fns";
import { ja } from "date-fns/locale";

export default function Post({
  pageContext: { node },
}: PageProps<{}, { node: GatsbyTypes.SanityBlogPost }>) {
  const { title, _rawBody, excerpt, _updatedAt } = node;
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const updatedAt = new Date(_updatedAt);

  return (
    <Layout>
      <Seo title={title} description={excerpt[0].children[0].text} />
      <Box component="main">
        <Box py={4} sx={{ backgroundColor: primaryColor }}>
          <Container maxWidth="md">
            <Stack>
              <Typography
                component="h1"
                variant="h5"
                align="center"
                color={(theme) => theme.palette.getContrastText(primaryColor)}
                fontWeight={600}
              >
                <span dangerouslySetInnerHTML={{ __html: useBudoux(title) }} />
              </Typography>
              <Box mt={2} textAlign="center" color="#f0f0f0">
                <Typography component="span" variant="body2">
                  <time dateTime={updatedAt.toISOString()}>
                    {format(updatedAt, "yyyy年MM月dd日")}
                  </time>
                </Typography>
                <Typography variant="caption">
                  （
                  {formatDistanceToNow(updatedAt, {
                    locale: ja,
                    addSuffix: true,
                  })}
                  ）
                </Typography>
              </Box>
            </Stack>
          </Container>
        </Box>
        <Box>
          <Container maxWidth="md">
            <Box py={3}>
              <PostTopSection />
            </Box>
            {_rawBody && <BlockContent blocks={_rawBody} />}
            <Box py={3}>
              <PostBottomSection />
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
}
