import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

export const InternalLink = ({ mark, children }) => {
  const data = useStaticQuery<GatsbyTypes.ReferenceQuery>(graphql`
    query Reference {
      allSanityService {
        edges {
          node {
            _id
            slug
          }
        }
      }
      allSanityBlogPost {
        edges {
          node {
            _id
            slug {
              current
            }
          }
        }
      }
    }
  `);
  const { allSanityBlogPost, allSanityService } = data;
  const serviceSlug = allSanityService.edges.find(
    ({ node }) => node._id === mark._ref
  )?.node?.slug;
  const postSlug = allSanityBlogPost.edges.find(
    ({ node }) => node._id === mark._ref
  )?.node?.slug?.current;

  return serviceSlug || postSlug ? (
    <Link to={postSlug ? `/blog/${postSlug}` : `/${serviceSlug}`}>
      {children}
    </Link>
  ) : (
    children
  );
};
