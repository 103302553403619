import React from "react";
import { useTheme } from "@mui/material";
import { Grid, useMediaQuery } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { CampaignBanner } from "../../../components/CampaignBanner";
import { isValidCampaign } from "../../../lib/helpers";

export const PostTopSection = () => {
  const data = useStaticQuery<GatsbyTypes.PostTopAdsQuery>(graphql`
    query PostTopAds {
      allSanityService {
        edges {
          node {
            campaigns {
              _key
              _type
              _rawAd
              ad {
                _key
                _type
                html
                id
              }
              detail
              until
              url
            }
            name
            url
            _key
          }
        }
      }
    }
  `);
  const services = data.allSanityService.edges.filter(
    ({ node }) => node.campaigns.length > 0
  );
  const campaigns = services
    .flatMap(({ node }) =>
      node.campaigns.map((campaign) => ({ ...campaign, name: node.name }))
    )
    .filter(({ until }) => isValidCampaign(until));
  const firstAd = campaigns.splice(
    Math.floor(Math.random() * campaigns.length),
    1
  )[0];
  const secondAd = campaigns.splice(
    Math.floor(Math.random() * campaigns.length),
    1
  )[0];
  const theme = useTheme();
  const biggerMiddle = useMediaQuery(theme.breakpoints.up("md"));
  const ads = biggerMiddle ? [firstAd, secondAd] : [firstAd];

  return (
    <Grid container spacing={2}>
      {ads.map((campaign) => (
        <Grid
          item
          xs={12}
          sm={ads.length === 1 ? 12 : 6}
          key={campaign._key}
          display="flex"
        >
          <CampaignBanner {...campaign} />
        </Grid>
      ))}
    </Grid>
  );
};
