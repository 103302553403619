import React from "react";
import BaseBlockContent from "@sanity/block-content-to-react";
import { InternalLink } from "./InternalLink";
import { StyledParagraph, StyledHeadline } from "./StyledComponents";
import { Box } from "@mui/material";

const serializers = {
  types: {
    block({ node, children }: { node: { style: string }; children: string }) {
      switch (node.style) {
        case "h2":
          return <StyledHeadline level="h2">{children}</StyledHeadline>;
        case "h3":
          return <StyledHeadline level="h3">{children}</StyledHeadline>;
        case "h4":
          return <StyledHeadline level="h4">{children}</StyledHeadline>;

        default:
          return <StyledParagraph>{children}</StyledParagraph>;
      }
    },
  },
  marks: { internalLink: InternalLink },
};

export const BlockContent = ({ blocks }) => {
  return (
    <Box
      sx={{
        ul: {
          listStyle: "disc",
          mb: 3,

          li: {
            ml: 3,
            mb: 1,
          },
        },
      }}
    >
      <BaseBlockContent blocks={blocks} serializers={serializers} />
    </Box>
  );
};
