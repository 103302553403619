import React from "react";
import { styled } from "@mui/material";

export const StyledParagraph = styled("p")({
  marginBottom: "1rem",
});

export const StyledHeadline = (props: {
  level: "h2" | "h3" | "h4";
  children: any;
}) => {
  const StyledHeadline = styled(props.level)({
    margin: "1.5rem auto",
  });
  return <StyledHeadline>{props.children}</StyledHeadline>;
};
